import React, { useEffect, useRef, useState } from "react";

import WinningPopup from "../components/GamePage/WinningPopup";

import { useSocket } from "../contexts/SocketContext";
import { usePageContext } from "../contexts/PageContext";

function GamePage() {
    const socket = useSocket();
    const { setPage } = usePageContext();

    // Matrix to use as blocks coordinates
    const blocks = Array.from(Array(4).keys()).map(() => Array.from(Array(4).keys()));

    const [ isPopupVisible, setIsPopupVisible ] = useState(false);
    const showPopup = () => setIsPopupVisible(true);
    const hidePopup = () => setIsPopupVisible(false);

    const [ gameResult, setGameResult ] = useState({});
    const [ chosenBlocks, setChosenBlocks ] = useState([[], [], [], []]);
    
    const chooseBlock = (i, j) => {
        if(chosenBlocks.find(block => block[0]===i && block[1]===j)) return;

        for(let index = 0; index < chosenBlocks.length; index++) {
            const block = chosenBlocks[index];
            if(block.length!==0) continue;

            const chosenBlocksCopy = [...chosenBlocks];
            chosenBlocksCopy[index] = [i, j];

            setChosenBlocks(chosenBlocksCopy);

            break;
        };
    };

    const countWin = () => {
        socket.emit("getGameResult", { chosenBlocks: chosenBlocks });
    };

    useEffect(() => {
        if(chosenBlocks.every(block => block.length!==0)) {
            countWin();
        };
    }, [chosenBlocks]);

    useEffect(() => {
        socket.on("gameResult", (data) => {
            setGameResult(data.result);
            showPopup();

            socket.emit("getPoints");
        });

        window.Telegram.WebApp.BackButton.show();
        window.Telegram.WebApp.BackButton.onClick(() => {
            setPage("earn");
        });
    }, []);

    return (
        <div id="game-page">
            <div className="fade-in-eclipse"></div>

            <h1>Mouse hunter</h1>

            <div id="blocks">
                <h2>Choose 4 squares</h2>
                <div className="blocks-container">
                    { 
                        blocks.map((row, i) => 
                            row.map((_, j) => 
                                <div 
                                    key={`${i};${j}`}
                                    className="block"
                                    style={{
                                        backgroundImage: `url("/images/game/${i};${j}.png")`,
                                        opacity: chosenBlocks.find(chosenBlock => chosenBlock[0]==i && chosenBlock[1]==j) ? "30%" : "100%"
                                    }}
                                    onClick={() => chooseBlock(i, j)}
                                ></div>
                            )
                        )
                    }
                </div>
            </div>

            <h3>
                Squeak! Squeak! <br /> 
                cheese is somewhere nearby I can feel it.<br /> 
                Collect 4 pictures in a combo and earn an extra 500 MOUSE
            </h3>

            <div id="chosen-blocks">
                {
                    chosenBlocks.map((block, index) =>     
                        <div
                            key={index}
                            style={{ backgroundImage: block ? `url("/images/game/${block[0]};${block[1]}.png")` : "none" }}
                        ></div>
                    )
                }
            </div>

            {
                isPopupVisible &&
                <WinningPopup win={gameResult.isWin} reward={gameResult.reward} hidePopup={hidePopup} />
            }
        </div>
    );
};

export default GamePage;